import React, { useRef } from 'react'

import PersistentContentEditable from '../../../Components/PersistentContentEditable'

import { keyboardLanguages } from '../../../StaticData/SiteData.js'

const FreeText = React.memo(({ i18n, kbLangKey }) => {
    const textbox1 = useRef(null)

    return (
        <div className='freetext-sect'>
            <div className='freetextdiv'>
                <style jsx>
                    {`
                        .freetextdiv {
                            height: 100%;
                            position: relative;
                        }
                        .freetextdiv :global(textarea),
                        .freetextdiv :global(.textbox1) {
                            text-align: left;
                            display: inline-block;
                            width: 700px;
                            color: #363636;
                            margin-bottom: 0;
                            min-height: 105px;
                            background-color: white;
                            padding: 8px 7px;
                            line-height: 1.15;
                            padding: 10px;
                            padding-bottom: 50px;
                        }
                        @media (max-width: 720px) {
                            .freetextdiv :global(textarea),
                            .freetextdiv :global(.textbox1) {
                                min-height: 100px;
                            }
                        }
                        @media (max-width: 979px) {
                            .freetextdiv :global(textarea),
                            .freetextdiv :global(.textbox1) {
                                font-size: 26px;
                                padding-top: 7px;
                            }
                        }
                        @media (max-width: 720px) {
                            .freetextdiv :global(textarea),
                            .freetextdiv :global(.textbox1) {
                                font-size: 23px;
                                width: 97%;
                                padding: 10px;
                                min-height: 50vh;
                            }
                        }
                        @media (max-width: 450px) {
                            .freetextdiv :global(textarea),
                            .freetextdiv :global(.textbox1) {
                                width: 96%;
                            }
                        }
                    `}
                </style>
                {/* dynamic styles: */}
                <PersistentContentEditable
                    typeHereString={i18n.t('UI.Type here')}
                    focus={true}
                    className={`defaultvkinput freetextarea query_text textbox1`}
                    adaptiveFontSize={true}
                    innerRef={textbox1}
                    id='textbox1_freetext'
                    dataSaveId='textbox1'
                    name='q'
                    readOnly='readonly'
                    role='textbox'
                    showEditBar={true}
                    rtl={keyboardLanguages.data[kbLangKey].rtl}
                    tabIndex='0'
                />
            </div>
        </div>
    )
})

FreeText.displayName = 'FreeText'
export default FreeText
