// import { I18nContext } from "next-i18next";
// const { locales } = require("next/config").default().publicRuntimeConfig;
import MyAppInternal from '../../components/MyAppInternal'
import Keyboard from '../../src/Pages/Keyboard'
import FreeText from '../../src/Pages/Keyboard/FreeText'
import { common_getProps } from '../../components/common_getProps'
import kbLangSlugToKey from '../../public/kbLangSlugToKey.json'

const KbLang = (props) => {
    return (
        <MyAppInternal {...props}>
            <Keyboard>
                <FreeText />
            </Keyboard>
        </MyAppInternal>
    )
}

// export async function getServerSideProps(context) {
//     return common_getServerSideProps(context)
// }

export async function getStaticProps(context) {
    return common_getProps(context)
}

export async function getStaticPaths() {
    return {
        paths: Object.keys(kbLangSlugToKey).flatMap((locale) =>
            Object.keys(kbLangSlugToKey[locale]).map((slug) => ({ params: { kbLangSlug: encodeURI(slug) }, locale }))
        ),
        fallback: true
    }
}

export default KbLang
